import { tns } from 'tiny-slider'

window.addEventListener('load', init)

function init() {

    const wrapper = document.querySelector('.brand-sliders')

    if(!wrapper) return

    const brandContainers = Array.from(wrapper.querySelectorAll('.brand-slider-wrapper'))

    if(brandContainers.length < 1) return

    brandContainers.forEach(brand => {
        const container = brand.querySelector('.brand-slider')
        const controlsContainer = brand.querySelector('.brand-slider-controls')

        const slider = tns({
            container,
            controlsContainer
        })

        slider.events.on('indexChanged', (slider) => {
            const activeSlider = wrapper.querySelector('.brand-slider-wrapper.active')
            const style = slider.container.querySelector('.tns-slide-active').getAttribute('style')
            activeSlider.setAttribute('style', style)
        })
    })

    const inputs = Array.from(document.querySelectorAll('.brands-list input'))

    function toggleSlider(id) {
        brandContainers.forEach(slider => {
            if(slider.dataset['brand'] === id) {
                slider.classList.add('active')
            } else {
                slider.classList.remove('active')
            }
        })
    }

    function updateTrigger(input) {
        const picker = document.querySelector('.brand-picker-active')
        picker.parentNode.querySelector('input').checked = false
        picker.innerHTML = input.parentNode.querySelector('label').innerHTML
    }

    inputs.forEach(input => {
        input.addEventListener('change', () => {
            const id = input.getAttribute('id')
            toggleSlider(id)
            updateTrigger(input)
        })
    })
}
