(() => {

    const SOCIAL = document.querySelector('.social-share')

    if(!SOCIAL) return

    const LINKS = Array.from(SOCIAL.querySelectorAll('.social-link'))

    if(!LINKS || LINKS.length === 0) return

    LINKS.forEach((link) => {
        link.querySelector('svg').style.pointerEvents = 'none'
        link.addEventListener("click", (e) => {
            e.preventDefault();

            let origEl = e.target || e.srcElement
            let url = origEl.parentNode.href || origEl.href

            let width = 575
            let height = 400

            let left = (document.documentElement.clientWidth / 2 - width / 2)
            let top = (document.documentElement.clientHeight - height) / 2

            let opts = 'status=1,resizable=yes' +
                ',width=' + width + ',height=' + height +
                ',top=' + top + ',left=' + left

            let win = window.open(url, '', opts)
            win.focus()
        })
    })

})()
